import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
          <div>
            <h2>About</h2>
            <p>This is an about page.</p>
          </div>
        );
      }
}

export default About;