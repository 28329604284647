import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
//import { formatPattern } from 'react-router/lib/PatternUtils';
import './App.css';

class MovieList extends Component {

    /*constructor(props) {
        super(props);
    }*/

    state = {
        movies: []
    }

    componentDidMount() {
      axios.get(`https://reelraja.com:5050/movies`)
        .then(res => {
          const movies = res.data;
          this.setState({ movies });
        });
    }
    
  render() {
    return (
      <div className="card-container">
        {this.state.movies.map(movie => <div className="card" key={movie.id}>
          <img src={movie.linkurl.replace('http', 'https')} alt={movie.id} style={{width: 200, height: 230, position: 'relative'}}></img>
          <div className="container">
            <Link to={{
              pathname:`/movie/${movie.id}`
              }}>{movie.displayname}</Link>
          </div>
          </div>)}
      </div>
    );
  }
}

export default MovieList;
