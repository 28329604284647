import React, { Component } from 'react';
//import Header from './Header.js';
import MovieList from './MovieList.js';
//import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
        <MovieList />
    );
  }
}

export default App;
