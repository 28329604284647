import React, { Component } from 'react';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import './App.css';

class Movie extends Component {

    /*constructor(props) {
        super(props);
    }*/

    state = {
      movie: [],
      movName: "",
      movieLinks: []      
    }

  componentDidMount() {
    var movName = this.props.match.params.movname;

    this.setState({ movName });

    axios.get(`https://reelraja.com:5050/movies/${movName}/links`)
      .then(res => {
        const movieLinks = res.data;
        this.setState({ movieLinks });
      });
    
      axios.get(`https://reelraja.com:5050/movies/${movName}`)
      .then(res => {
        const movie = res.data[0];
        this.setState({ movie });
        console.log(res);
      });    
    }
    
  render() {
    let imageList = this.state.movieLinks.filter(link => link.linktype ==="twimg");

    return (    
      <div>        
        <h1>{this.state.movie.displayname}</h1>
        <h2>Announced On: {this.state.movie.announcedon}</h2>
        <h3>Last Updated On: {this.state.movie.updatedon}</h3>
        <h3>First Inserted On: {this.state.movie.insertdate}</h3>

        {imageList.map(link => (
          <img key={link.id_str} src={link.linkurl} alt={link.moviename} style={{ position: 'relative' }}></img>
          )
        )}
      </div>
    );
  }
}

export default Movie;
